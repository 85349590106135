<template>
  <footer
    class="d-flex align-items-center justify-content-center bg-dark text-white p-2"
  >
    <small>Happy Jungle &copy; 2024</small>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
